<template>
	<div class="grid">
		<div class="col-12">
			<div v-if="pageLoading">
				<div class="custom-skeleton">
					<div class="card">
                        <div class="flex mb-3">
                            <Skeleton height="8rem" class="mb-1"></Skeleton>
                        </div>
                        <Skeleton width="100%" height="4rem" class="my-1"></Skeleton>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
						<div class="flex align-items-end">
							<Skeleton height="3rem" class="my-1 mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem" class="mr-2"></Skeleton>
							<Skeleton shape="circle" size="3rem"></Skeleton>
						</div>
                    </div>
				</div>
			</div>
			<div v-else class="card p-2">
				<Toast/>
				<div class="surface-ground border-1 surface-border p-2">
					<div class="flex flex-grow-1 align-items-center justify-content-center my-2">
						<div class="flex flex-grow-1 align-items-center justify-content-start">
							<div class="text-xl font-semibold">Agency List</div>
						</div>
						<div class="flex flex-grow-1 align-items-center justify-content-end">
							<Button label="Create New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</div>
					<Divider></Divider>
						<div class="grid">
							<div class="col-12 md:col-4">
							<div class="field" >
								<label for="keyword">Keyword</label>
								<InputText
									class="w-full"
									id="keyword"
									v-model="keywordListValue"
									placeholder="Enter name or owner name"
								/>
							</div>
							</div>
						<div class="col-12 md:col-4">
							<div class="field">
								<label for="contact">Contact</label>
								<InputText
									class="w-full"
									id="contact"
									v-model="contactValue"
									placeholder="Enter email or mobile number"
								/>
							</div>
						</div>
						<div class="col-12 md:col-4 md:my-5">
							<div class="flex flex-wrap ">
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Clear" icon="pi pi-filter-slash" class="p-button-raised p-button-text w-full" @click="clearFilters"></Button>
								</div>
								<div class="flex-1 flex align-items-center justify-content-center mx-2">
									<Button label="Apply" icon="pi pi-filter" class="p-button-raised p-button-text w-full" @click="getFilteredList"></Button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DataTable v-if="agencyList.data != ''" ref="dt" :value="agencyList.data" dataKey="id" responsiveLayout="scroll">
					<Column field="id" header="ID" headerStyle="width:6%; min-width:4rem;">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							#00{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" header="Name" headerStyle="width:16%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="owner" header="Owner Name" headerStyle="width:16%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Owner Name</span>
							{{formatCurrency(slotProps.data.owner.name)}}
						</template>
					</Column>
					<Column field="registration_no" header="Registration No." headerStyle="width:13%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Registration No.</span>
							{{slotProps.data.registration_no}}
						</template>
					</Column>
					<Column field="primary_contact" header="Contact No." headerStyle="width:13%; min-width:6rem;">
						<template #body="slotProps">
							<span v-if="slotProps.data.primary_contact">
								{{slotProps.data.primary_contact}}
							</span>
						</template>
					</Column>
					<Column field="email" header="E-mail" headerStyle="width:13%; min-width:7rem;">
						<template #body="slotProps">
							<span class="p-column-title">E-mail</span>
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="package" header="Package" headerStyle="width:12%; min-width:6rem;">
						<template #body="slotProps">
							<span class="p-column-title">Package</span>
							{{slotProps.data.package}}
						</template>
					</Column>
					<Column header="Actions" headerStyle="width:12%; min-width:11rem;">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-raised p-button-text p-button-rounded mr-2" @click="confirmDetailSelected(slotProps.data)" />
							<Button icon="pi pi-pencil" class="p-button-raised p-button-text p-button-rounded mr-2" @click="editOpen(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-raised p-button-text p-button-rounded p-button-danger mt-2" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
					<template #footer>
						<Paginator v-model:first="agencyList.from" :rows="paginationValue" :totalRecords="agencyList.total" :rowsPerPageOptions="[10,20,50]" @page="onPage" >
						</Paginator>
					</template>
				</DataTable>

				<template v-else>
					<div class="card">
						<div class="flex align-items-stretch justify-content-center flex-wrap card-container" style="min-height: 200px">
							<div class="flex align-items-center justify-content-center">
								<Message severity="info" :closable="false">No agency found!</Message>
							</div>
						</div>
					</div>
				</template>

				<Dialog v-model:visible="agencyDialog" :style="{width: '650px'}" :header="Header" :modal="true" class="p-fluid" :closable="false">
					<div class="mb-2 -mt-1 "><small>* <span class="text-orange-600">Required</span></small></div>
					<div class="field">
						<label for="name">Name <span>*</span></label>
						<InputText
							id="name"
							v-model="valid$.name.$model"
							required="true"
							placeholder="Enter Agency Name"
							:class="{'p-invalid': valid$.name.$invalid && submitted}"
						/>
						<span v-if="valid$.name.$error && submitted">
							<span
								id="name-error"
								v-for="(error, index) of valid$.name.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.name.$invalid && submitted) || valid$.name.$pending.$response"
							class="p-error"
						>
							{{ valid$.name.required.$message.replace("Value", "Name") }}
						</small>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="owner_id">Owner <span>*</span></label>
							<Dropdown
								:class="{'p-invalid': valid$.owner_id.$invalid && submitted}"
								id="owner_id"
								:options="userList"
								optionLabel="optionLabel"
								optionValue="id"
								:filter="true"
								v-model="valid$.owner_id.$model"
								placeholder="Select a Owner"
							/>
							<span v-if="valid$.owner_id.$error && submitted">
								<span
									id="owner_id-error"
									v-for="(error, index) of valid$.owner_id.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Owner Id")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.owner_id.$invalid && submitted) || valid$.owner_id.$pending.$response"
								class="p-error"
							>
								{{ valid$.owner_id.required.$message.replace("Value", "Owner Id") }}
							</small>
							<small
								v-if="valid$.owner_id.$model.role == 'owner'"
								class="p-error"
							>
								<p>This user already has an agency !</p>
							</small>
						</div>

						<div class="field col">
							<label for="registration_no">Registration No <span>*</span></label>
							<InputText
								id="registration_no"
								v-model="valid$.registration_no.$model"
								:class="{'p-invalid': valid$.registration_no.$invalid && submitted}"
								placeholder="Enter Registration No"
							/>
							<span v-if="valid$.registration_no.$error && submitted">
								<span
									id="registration_no-error"
									v-for="(error, index) of valid$.registration_no.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Registration No")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.registration_no.$invalid && submitted) || valid$.registration_no.$pending.$response"
								class="p-error"
							>
								{{ valid$.registration_no.required.$message.replace("Value", "Registration No") }}
							</small>
						</div>
					</div>
					
					<div class="formgrid grid">
						<div class="field col">
							<label for="primary_contact">Primary Contact <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.primary_contact.$invalid && submitted}"
								id="primary_contact"
								v-model="valid$.primary_contact.$model"
								placeholder="Enter Primary Contact"
							/>
							<span v-if="valid$.primary_contact.$error && submitted">
								<span
									id="primary_contact-error"
									v-for="(error, index) of valid$.primary_contact.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Primary Contact")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.primary_contact.$invalid && submitted) || valid$.primary_contact.$pending.$response"
								class="p-error"
							>
								{{ valid$.primary_contact.required.$message.replace("Value", "Primary Contact") }}
							</small>
						</div>

						<div class="field col">
							<label for="secondary_contact">Secondary Contact <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.secondary_contact.$invalid && submitted}"
								id="secondary_contact"
								v-model="valid$.secondary_contact.$model"
								placeholder="Enter Secondary Contact"
							/>
							<span v-if="valid$.secondary_contact.$error && submitted">
								<span
									id="secondary_contact-error"
									v-for="(error, index) of valid$.secondary_contact.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Secondary Contact")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.secondary_contact.$invalid && submitted) || valid$.secondary_contact.$pending.$response"
								class="p-error"
							>
								{{ valid$.secondary_contact.required.$message.replace("Value", "Secondary Contact") }}
							</small>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="email">E-mail <span>*</span></label>
							<InputText
								:class="{'p-invalid': valid$.email.$invalid && submitted}"
								id="email"
								v-model="valid$.email.$model"
								type="email"
								placeholder="Enter E-mail"
							/>
							<span v-if="valid$.email.$error && submitted">
								<span
									id="email-error"
									v-for="(error, index) of valid$.email.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "E-mail")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.email.$invalid && submitted) || valid$.email.$pending.$response"
								class="p-error"
							>
								{{ valid$.email.required.$message.replace("Value", "E-mail") }}
							</small>
						</div>

						<div class="field col">
							<label for="package">Package <span>*</span></label>
							<Dropdown
								:class="{'p-invalid': valid$.package.$invalid && submitted}"
								id="package"
								:options="packages"
								optionLabel="name"
								optionValue="value"
								:filter="true"
								v-model="valid$.package.$model"
								placeholder="Select a package"
								@change="selectPackage(valid$.package.$model)"
							/>
							<span v-if="valid$.package.$error && submitted">
								<span
									id="package-error"
									v-for="(error, index) of valid$.package.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Package")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.package.$invalid && submitted) || valid$.package.$pending.$response"
								class="p-error"
							>
								{{ valid$.package.required.$message.replace("Value", "Package") }}
							</small>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="tour_limit">Tour Limit <span>*</span></label>
							<InputNumber
								:class="{'p-invalid': valid$.tour_limit.$invalid && submitted}"
								id="tour_limit"
								v-model="valid$.tour_limit.$model"
								min="0"
								placeholder="Enter Tour Limit"
							/>
							<span v-if="valid$.tour_limit.$error && submitted">
								<span
									id="tour_limit-error"
									v-for="(error, index) of valid$.tour_limit.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Tour Limit")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.tour_limit.$invalid && submitted) || valid$.tour_limit.$pending.$response"
								class="p-error"
							>
								{{ valid$.tour_limit.required.$message.replace("Value", "Tour Limit") }}
							</small>
						</div>

						<div class="field col">
							<label for="service_charge">Service Charge <span>*</span></label>
							<div class="p-inputgroup">
								<InputNumber
									:class="{'p-invalid': valid$.service_charge.$invalid && submitted}"
									id="service_charge"
									v-model="valid$.service_charge.$model"
									placeholder="Enter Service Charge"
									max="100"
								/>
								<span class="p-inputgroup-addon">%</span>
							</div>
							<span v-if="valid$.service_charge.$error && submitted">
								<span
									id="service_charge-error"
									v-for="(error, index) of valid$.service_charge.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Service Charge")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.service_charge.$invalid && submitted) || valid$.service_charge.$pending.$response"
								class="p-error"
							>
								{{ valid$.service_charge.required.$message.replace("Value", "Service Charge") }}
							</small>
						</div>
					</div>

					<div class="field">
						<label for="tour_policy">Tour Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.tour_policy.$invalid && submitted}"
							id="tour_policy"
							v-model="valid$.tour_policy.$model"
							:autoResize="true"
							placeholder="Enter Tour Policy"
						/>
						<span v-if="valid$.tour_policy.$error && submitted">
							<span
								id="tour_policy-error"
								v-for="(error, index) of valid$.tour_policy.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Tour Policy")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.tour_policy.$invalid && submitted) || valid$.tour_policy.$pending.$response"
							class="p-error"
						>
							{{ valid$.tour_policy.required.$message.replace("Value", "Tour Policy") }}
						</small>
					</div>

					<div class="field">
						<label for="children_policy">Children Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.children_policy.$invalid && submitted}"
							id="children_policy"
							v-model="valid$.children_policy.$model"
							:autoResize="true"
							placeholder="Enter Children Policy"
						/>
						<span v-if="valid$.children_policy.$error && submitted">
							<span
								id="children_policy-error"
								v-for="(error, index) of valid$.children_policy.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Children Policy")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.children_policy.$invalid && submitted) || valid$.children_policy.$pending.$response"
							class="p-error"
						>
							{{ valid$.children_policy.required.$message.replace("Value", "Children Policy") }}
						</small>
					</div>

					<div class="field">
						<label for="cancellation_policy">Cancellation Policy</label>
						<Editor
							editorStyle="height: 85px"
							:class="{'p-invalid': valid$.cancellation_policy.$invalid && submitted}"
							id="cancellation_policy"
							v-model="valid$.cancellation_policy.$model"
							:autoResize="true"
							placeholder="Enter Cancellation Policy"
						/>
						<span v-if="valid$.cancellation_policy.$error && submitted">
							<span
								id="cancellation_policy-error"
								v-for="(error, index) of valid$.cancellation_policy.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Cancellation Policy")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.cancellation_policy.$invalid && submitted) || valid$.cancellation_policy.$pending.$response"
							class="p-error"
						>
							{{ valid$.cancellation_policy.required.$message.replace("Value", "Cancellation Policy") }}
						</small>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="country">Country <span>*</span></label>
							<InputText
								id="country"
								v-model="valid$.country.$model"
								disabled
								:class="{'p-invalid': valid$.country.$invalid && submitted}"
							/>
							<span v-if="valid$.country.$error && submitted">
								<span
									id="country-error"
									v-for="(error, index) of valid$.country.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Country")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.country.$invalid && submitted) || valid$.country.$pending.$response"
								class="p-error"
							>
								{{ valid$.country.required.$message.replace("Value", "Country") }}
							</small>
						</div>
						<div class="field col">
							<label for="division">Division <span>*</span></label>
							<Dropdown
								id="division"
								v-model="valid$.division.$model"
								@change="getDistrict(valid$.division.$model)"
								:options="divisionList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your Division"
								:class="{'p-invalid': valid$.division.$invalid && submitted}"
							/>
							<span v-if="valid$.division.$error && submitted">
								<span
									id="division-error"
									v-for="(error, index) of valid$.division.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Division")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.division.$invalid && submitted) || valid$.division.$pending.$response"
								class="p-error"
							>
								{{ valid$.division.required.$message.replace("Value", "Division") }}
							</small>
						</div>
					</div>

					<div class="formgrid grid">
						<div class="field col">
							<label for="district">District <span>*</span></label>
							<Dropdown
								id="district"
								v-model="valid$.district.$model"
								@change="getThana(valid$.district.$model)"
								:options="districtList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your District"
								:class="{'p-invalid': valid$.district.$invalid && submitted}"
							/>
							<span v-if="valid$.district.$error && submitted">
								<span
									id="district-error"
									v-for="(error, index) of valid$.district.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "District")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.district.$invalid && submitted) || valid$.district.$pending.$response"
								class="p-error"
							>
								{{ valid$.district.required.$message.replace("Value", "District") }}
							</small>
						</div>
						<div class="field col">
							<label for="thana">Thana <span>*</span></label>
							<Dropdown
								id="thana"
								v-model="valid$.thana.$model"
								:options="thanaList"
								:filter="true"
								optionLabel="value"
								optionValue="value"
								placeholder="Select your Thana"
								:class="{'p-invalid': valid$.thana.$invalid && submitted}"
							/>
							<span v-if="valid$.thana.$error && submitted">
								<span
									id="thana-error"
									v-for="(error, index) of valid$.thana.$errors"
									:key="index"
								>
									<small class="p-error">{{error.$message.replace("Value", "Thana")}}</small>
								</span>
							</span>
							<small
								v-else-if="(valid$.thana.$invalid && submitted) || valid$.thana.$pending.$response"
								class="p-error"
							>
								{{ valid$.thana.required.$message.replace("Value", "Thana") }}
							</small>
						</div>
					</div>

					<div class="field">
						<label for="line">Line</label>
						<Textarea
							:class="{'p-invalid': valid$.line.$invalid && submitted}"
							id="line"
							v-model="valid$.line.$model"
							:autoResize="true"
							placeholder="Enter Line"
						/>
						<span v-if="valid$.line.$error && submitted">
							<span
								id="line-error"
								v-for="(error, index) of valid$.line.$errors"
								:key="index"
							>
								<small class="p-error">{{error.$message.replace("Value", "Line")}}</small>
							</span>
						</span>
						<small
							v-else-if="(valid$.line.$invalid && submitted) || valid$.line.$pending.$response"
							class="p-error"
						>
							{{ valid$.line.required.$message.replace("Value", "Line") }}
						</small>
					</div>

					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button v-if="selectedItem != null" label="Update" icon="pi pi-check" class="p-button p-button-raised" @click="Edit(!valid$.$invalid)" :loading="loading"/>
						<Button v-else label="Create" icon="pi pi-check" class="p-button p-button-raised" @click="saveAgency(!valid$.$invalid)" :loading="loading"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteDialog" :style="{width: '550px'}" header="Confirm" :modal="true" :closable="false">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="selectedItem">Are you sure you want to delete <b>{{selectedItem.name}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-raised p-button-danger" @click="Delete" />
					</template>
				</Dialog>

				<Dialog v-model:visible="closeDialog" :style="{width: '550px'}" header="Agency Details" :modal="true" :closable="false">
					<div class="surface-section">
						<ul class="list-none p-0 m-0">
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">ID</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">#00{{selectedItem.id}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Owner Name</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.owner.name}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Registration No</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.registration_no}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Primary Contact No</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.primary_contact}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Alternative Contact No</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.secondary_contact}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">E-mail</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.email}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Package</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.package}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Tour Limit</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.tour_limit}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Service Charge</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.service_charge}}%</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Rating</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.rating.average}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Address</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.address.line}}, {{selectedItem.address.thana}}, {{selectedItem.address.district}}, {{selectedItem.address.division}}, {{selectedItem.address.country}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Tour Policy</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-html="selectedItem.tour_policy"></div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Children<br>Policy</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-html="selectedItem.children_policy"></div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Cancellation Policy</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" v-html="selectedItem.cancellation_policy"></div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Created At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.created_at ? formatTime(selectedItem.created_at) : '-'}}</div>
							</li>
							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-8 md:w-3 font-medium">Updated At</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{selectedItem.updated_at ? formatTime(selectedItem.updated_at) : '-'}}</div>
							</li>
						</ul>
					</div>
					<template #footer>
						<Button label="Close" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import { reactive, ref } from 'vue';
import Axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, email, numeric, minValue, maxValue } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import GlobalValues from '../data/GlobalValues';

export default {
	setup() {
		const loading = ref(false);
		const pageLoading = ref(true);
		const agencyList = ref(null);
		const userList = ref(null);

		const agencyDialog = ref(false);
		const deleteDialog = ref(false);
		const closeDialog = ref(false);

		const selectedItem = ref(null);
		const filters = ref({});
		const submitted = ref(false);
		const keywordList = ref([]);
		const keywordListValue = ref();
		const contactValue = ref();
		const paginationValue = ref(10);
		const pageValue = ref();
		const Header = ref('Create Agency');

		const divisionList = ref();
		const districtList = ref();
		const thanaList = ref();

		const toast = useToast();
		const router = useRouter();
		const route = useRoute();
		const globalValues = ref(new GlobalValues());

		const packages = ref([
			{name: 'Basic', value: 'Basic'},
			{name: 'SME', value: 'SME'},
			{name: 'Business', value: 'Business'},
			{name: 'Enterprise', value: 'Enterprise'}
		]);

		const state = reactive({
			name : '',
			owner_id : '',
			registration_no : '',
			primary_contact : null,
			secondary_contact : null,
			email : '',
			package : '',
			tour_limit : '',
			service_charge : '',
			tour_policy : '',
			children_policy : '',
			cancellation_policy : '',
			line : '',
			thana : '',
			district : '',
			division : '',
			country : 'Bangladesh',
		});

		const rules = {
			name : {required, maxLength: maxLength(255), minLength: minLength(3)},
			owner_id : {required},
			registration_no : {required, maxLength: maxLength(255)},
			primary_contact : {required, numeric, maxLength: maxLength(255)},
			secondary_contact : {required, numeric, maxLength: maxLength(255)},
			email : {required, email},
			package : {required, maxLength: maxLength(255)},
			tour_limit : {required, numeric, maxLength: maxLength(10), minValue : minValue(0)},
			service_charge : {required, numeric, minValue: minValue(0), maxValue: maxValue(100)},
			line : {},
			thana : {required},
			district : {required},
			division : {required},
			country : {required},
			tour_policy : {},
			children_policy : {},
			cancellation_policy : {},
		};

		const valid$ = useVuelidate (rules, state);

		const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            if(route.query.keyword != null){
                keywordListValue.value = route.query.keyword;
            }
            if(route.query.contact != null){
                contactValue.value = route.query.contact;
            }
        };
        getActiveQuerys();

		const getAgencylist = () =>{
			Axios
			.get("api/admin/agency/list",{
				params: {
					page: pageValue.value,
                    pagination: paginationValue.value,
                    keyword: keywordListValue.value,
					contact: contactValue.value,
                }
			}
			)
			.then((res) =>{
				pageLoading.value = false;
				if (res.data.response == "success") {
					agencyList.value = res.data.data;
					for (let agency of agencyList.value.data) {
						agency.primary_contact = agency.primary_contact ? formatMobileNumber(agency.primary_contact) : '';
						agency.secondary_contact = agency.secondary_contact ? formatMobileNumber(agency.secondary_contact) : '';
					}					
					agencyList.value.from = agencyList.value.from-1;
					console.log(agencyList.value);
				}
				else{
					console.log(res.data);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getAgencylist();

		//USER LIST
		const getUserlist = () =>{
			Axios
			.get("api/admin/user/dropdown-list")
			.then((res) =>{
				if (res.data.response == "success") {
					userList.value = res.data.data;
					for (let item of userList.value) {
						item.optionLabel = item.name + ' (' + item.email + ')';
					}
				}
				else{
					console.log(res.data.msg);
				}
			})
			.catch((err) =>{
				console.log(err);
			})
		};
		getUserlist();

		//ADDRESSES
		const getDivision = () => {
            divisionList.value = globalValues.value.getDivisions(state.country);

            state.division = null;
            state.district = null;
            state.thana = null;
        }
        getDivision();
        const getDistrict = (division) => {
            districtList.value = globalValues.value.getDistricts(division);

            state.district = null;
            state.thana = null;
        };
        const getThana = (district) => {
            thanaList.value = globalValues.value.getThanas(district);
        };

		const showToast = (severity, summary, detail) => {
			toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
		};

		const formatDate = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY HH:MM A");
        };

		const formatCurrency = (value) => {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'BDT'});
			return;
		};

		const formatTime = (rawDate) => {
            return moment(rawDate).format("Do MMMM YYYY hh:mm:ss a");
        };

		const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

		const openNew = () => {
			submitted.value = false;
			agencyDialog.value = true;
		};

		const selectPackage = (seclectedPackage) => {
			if (seclectedPackage == 'Basic') {
				state.tour_limit = 2;
				state.service_charge = 12;
			} else if (seclectedPackage == 'SME') {
				state.tour_limit = 4;
				state.service_charge = 12;
			} else if (seclectedPackage == 'Business') {
				state.tour_limit = 8;
				state.service_charge = 8;
			} else if (seclectedPackage == 'Enterprise') {
				state.tour_limit = 0;
				state.service_charge = 5;
			}
			console.log(seclectedPackage);
		}

		const hideDialog = () => {
			selectedItem.value = null;
			agencyDialog.value = false;
			closeDialog.value = false;
			submitted.value = false;
			Header.value = 'Create Agency';

			state.name = '';
			state.owner_id = '';
			state.registration_no = '';
			state.primary_contact = null;
			state.secondary_contact = null;
			state.email = '';
			state.package = '';
			state.tour_limit = '';
			state.service_charge = '';
			state.tour_policy = '';
			state.children_policy = '';
			state.cancellation_policy = '';
			state.line = '';
			state.thana = '';
			state.district = '';
			state.division = '';
		};

		//CREATE
		const saveAgency = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
				return;
            }

			console.log(state);
			loading.value = true;
			const formData1 = new FormData();

			formData1.append("name", state.name);
			formData1.append("owner_id", state.owner_id);
			formData1.append("registration_no", state.registration_no);
			formData1.append("primary_contact", state.primary_contact);
			formData1.append("secondary_contact", state.secondary_contact);
			formData1.append("email", state.email);
			formData1.append("package", state.package);
			formData1.append("tour_limit", state.tour_limit);
			formData1.append("service_charge", state.service_charge);
			if(state.tour_policy)formData1.append("tour_policy", state.tour_policy);
			if(state.children_policy)formData1.append("children_policy", state.children_policy);
			if(state.cancellation_policy)formData1.append("cancellation_policy", state.cancellation_policy);
			formData1.append("line", state.line);
			formData1.append("thana", state.thana);
			formData1.append("district", state.district);
			formData1.append("division", state.division);
			formData1.append("country", state.country);

			Axios
            .post("api/admin/agency/create", formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					agencyDialog.value = false;
					getAgencylist();
					state.name = '';
					state.owner_id = '';
					state.registration_no = '';
					state.primary_contact = null;
					state.secondary_contact = null;
					state.email = '';
					state.package = '';
					state.tour_limit = '';
					state.service_charge = '';
					state.tour_policy = '';
					state.children_policy = '';
					state.cancellation_policy = '';
					state.line = '';
					state.thana = '';
					state.district = '';
					state.division = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
				loading.value = false;
                console.log(err);
                showToast("error", "Error", err);
            });
		};

		//READ
		const confirmDetailSelected = (product) => {
			selectedItem.value = product;
			closeDialog.value = true;
		};
		
		//DELETE
		const confirmDelete = (product) => {
			selectedItem.value = product;
			deleteDialog.value = true;
		};

		const Delete = () => {
			Axios
			.delete("api/admin/agency/delete/"+selectedItem.value.id)
			.then(res => {
				if(res.data.response == "success"){
					showToast("success", "Success", res.data.message);
					getAgencylist();
					deleteDialog.value = false;
				}
				else{
					showToast("error", "Error", res.data.message);
				}
				console.log(res);
			})
			.catch(err => {
				console.log(err);
			})
		};

		//EDIT OR UPDATE
		const editOpen = (product) => {
			selectedItem.value = product;
			agencyDialog.value = true;
			Header.value = 'Edit Agency'

			state.name = selectedItem.value.name;
			state.owner_id = selectedItem.value.owner_id;
			state.registration_no = selectedItem.value.registration_no;
			state.primary_contact = selectedItem.value.primary_contact;
			state.secondary_contact = selectedItem.value.secondary_contact;
			state.email = selectedItem.value.email;
			state.package = selectedItem.value.package;
			state.tour_limit = selectedItem.value.tour_limit;
			state.service_charge = selectedItem.value.service_charge;
			state.tour_policy = selectedItem.value.tour_policy;
			state.children_policy = selectedItem.value.children_policy;
			state.cancellation_policy = selectedItem.value.cancellation_policy;
			state.line = selectedItem.value.address.line;
			state.thana = selectedItem.value.address.thana;
			state.district = selectedItem.value.address.district;
			state.division = selectedItem.value.address.division;

			const getDivisionU = () => {
				divisionList.value = globalValues.value.getDivisions(state.country);
			}
			const getDistrictU = (division) => {
				districtList.value = globalValues.value.getDistricts(division);
			};
			const getThanaU = (district) => {
				thanaList.value = globalValues.value.getThanas(district);
			};

			getDivisionU();
			getDistrictU(selectedItem.value.address.division);
			getThanaU(selectedItem.value.address.district);
		};

		const Edit = (isFormValid) => {
			submitted.value = true;
            if (!isFormValid) {
                return;
            }

			const formData1 = new FormData();

			formData1.append("name", state.name);
			formData1.append("owner_id", state.owner_id);
			formData1.append("registration_no", state.registration_no);
			formData1.append("primary_contact", state.primary_contact);
			formData1.append("secondary_contact", state.secondary_contact);
			formData1.append("email", state.email);
			formData1.append("package", state.package);
			formData1.append("tour_limit", state.tour_limit);
			formData1.append("service_charge", state.service_charge);
			formData1.append("tour_policy", state.tour_policy);
			formData1.append("children_policy", state.children_policy);
			formData1.append("cancellation_policy", state.cancellation_policy);
			formData1.append("line", state.line);
			formData1.append("thana", state.thana);
			formData1.append("district", state.district);
			formData1.append("division", state.division);
			formData1.append("country", state.country);

			formData1.append("_method", "PUT");

			Axios
            .post(`api/admin/agency/update/${selectedItem.value.id}`, formData1, {
                config: {
                    headers: {'Content-Type': 'multipart/form-data' }
                }
            })
            .then( (res) => {
                loading.value = false;
                if(res.data.response=="success") {
                    showToast("success", "Success", res.data.message);
					selectedItem.value = null;
					agencyDialog.value = false;
					getAgencylist();
					state.name = '';
					state.owner_id = '';
					state.registration_no = '';
					state.primary_contact = null;
					state.secondary_contact = null;
					state.email = '';
					state.package = '';
					state.tour_limit = '';
					state.service_charge = '';
					state.tour_policy = '';
					state.children_policy = '';
					state.cancellation_policy = '';
					state.line = '';
					state.thana = '';
					state.district = '';
					state.division = '';
                }
                else{
                    showToast("error", "Error", res.data.message);
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                showToast("error", "Error", err.message);
                console.log(err);
            });
		};

		//FILTER
		const getFilteredList = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(keywordListValue.value != null){
                queryParams.keyword = keywordListValue.value;
            }
            if(contactValue.value != null){
                queryParams.discount = contactValue.value;
            }

            router.replace({ name: 'agencies', query: { ...queryParams } });

            getAgencylist();
        };

        const clearFilters = () => {
            pageLoading.value = true;
            // showFilterMenu.value = false;

            keywordListValue.value = null;
            contactValue.value = null;
            router.replace();

            getAgencylist();
        };

		const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;
            // showFilterMenu.value = false;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'agencies', query: { ...queryParams } });

            getAgencylist();
		}

		return{
			pageLoading,
			valid$,
			agencyDialog,
			deleteDialog,
			closeDialog,
			selectedItem,
			filters,
			submitted,
			agencyList,
			userList,
			packages,
			selectPackage,
			saveAgency,
			loading,
			formatDate,
			formatCurrency,
			formatTime,
			confirmDelete,
			Delete,
			editOpen,
			Edit,
			openNew,
			hideDialog,
			keywordList,
			keywordListValue,
			contactValue,
			clearFilters,
			getFilteredList,
			confirmDetailSelected,
			onPage,
			paginationValue,

			getDivision,
			getDistrict,
			getThana,
			divisionList,
			districtList,
			thanaList,
			Header
		}
	},
}
</script>

<style scoped lang="scss">
	@import '../assets/demo/badges.scss';
</style>
